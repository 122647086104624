<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-row class="max-width">
        <v-col>
          <v-select
            label="Empresa"
            :items="branches"
            v-model="form.organization_branch_id"
            item-value="id"
            item-text="name"
            :rules="$rules.required"
            required
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            label="Tipo de Exame"
            :items="subgroups"
            v-model="form.subgroup"
            item-value="code"
            item-text="sgr_nome"
            :rules="$rules.required"
            required
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="max-width">
        <v-col>
          <v-text-field
            v-model="form.margin_top"
            label="Margem Superior"
            :rules="$rules.number"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.margin_bottom"
            :rules="$rules.number"
            label="Margem Inferior"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="max-width">
        <v-col>
          <v-text-field
            v-model="form.header_height"
            label="Altura Cabeçalho"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.footer_height"
            label="Altura Rodapé"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="max-width">
        <v-col>
          <v-file-input
            v-model="backgroundFile"
            dense
            label="Imagem de background"
            outlined
            truncate-length="15"
          ></v-file-input>
        </v-col>
      </v-row>

      <v-row
        v-if="backgroundURL && form.background_nj_id"
        class="max-width mb-8"
      >
        <v-col>
          <v-img max-height="150" max-width="250" :src="backgroundURL"></v-img>
        </v-col>
        <v-col>
          <v-btn
            @click="removeBackground"
            :disabled="removingBackground || $attrs.disabled"
          >
            Remover Imagem de Background
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mb-4">
        <v-col cols="8">
          <v-tabs class="nj-tabs" v-model="tab">
            <v-tab>Cabeçalho</v-tab>
            <v-tab>Rodapé</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" eager>
            <v-tab-item class="py-4" eager>
              <nj-editor
                :disabled="$attrs.disabled"
                ref="headerEditor"
                :custom-events="customEventsHeader"
                v-model="form.header"
              >
              </nj-editor>
            </v-tab-item>
            <v-tab-item class="py-4">
              <nj-editor
                :disabled="$attrs.disabled"
                ref="footerEditor"
                :custom-events="customEventsFooter"
                v-model="form.footer"
              >
              </nj-editor>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col cols="4" class="font-14">
          <p>Parâmetros disponíves:</p>
          <div v-for="(param, index) in params" :key="index">
            <div v-text="`{{${param}}}`"></div>
          </div>
        </v-col>
      </v-row>

      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 600px;
}
</style>
<script>
import axios from 'axios'
import Api from '@/resources/NajaSocialApi'
import EditorUpload from '@/resources/editorUpload'
import ErrorMessages from '@/components/ErrorMessages'
const PARAMS = [
  'NOME_PACIENTE',
  'IDADE_PACIENTE',
  'CODIGO_PACIENTE',
  'ORIGEM_PACIENTE',
  'NOME_MAE_PACIENTE',
  'DATA_NASCIMENTO_PACIENTE',
  'MARCA_MASCULINO',
  'MARCA_FEMINIMO',
  'DATA_LAUDO',
  'DATA_LAUDO_EXTENSO',
  'HORA_LAUDO',
  'DATA_ATENDIMENTO',
  'DATA_ATENDIMENTO_EXTENSO',
  'PREFIXO_ATENDIMENTO',
  'NUMERO_ATENDIMENTO',
  'HORA_PEDIDO',
  'NUMERO_PEDIDO',
  'NOME_MEDICO_RESPONSAVEL',
  'CRM_MEDICO_RESPONSAVEL',
  'ESPECIALIDADE_MEDICO_RESPONSVAL',
  'CODIGO_CONSELHO_ESPECIALIDADE',
  'NOME_MEDICO_SOLICITANTE',
  'CRM_MEDICO_SOLICITANTE',
  'NOME_PROCEDIMENTO',
  'CONTROLE_NOME_PROCEDIMENTO',
  'NOME_CONVENIO',
  'QUARTO_LEITO',
  'DATA_COLETA',
  'USUARIO',
  'URL_EXAMES_WEB',
]

export default {
  name: 'HeaderFooterTemplateForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    backgroundFile: null,
    backgroundURL: null,
    removingBackground: false,
    loading: false,
    params: PARAMS,
    tab: 0,
    errors: [],
    branches: [],
    subgroups: [],
    customEventsHeader: {
      'image.beforeUpload': EditorUpload.beforeUpload,
    },
    customEventsFooter: {
      'image.beforeUpload': EditorUpload.beforeUpload,
    },
    form: {
      organization_branch_id: null,
      subgroup: null,
      margin_top: null,
      margin_bottom: null,
      header_height: null,
      footer_height: null,
      header: null,
      footer: null,
    },
  }),

  mounted() {
    this.fetch(this.$route.params.id)
  },

  watch: {
    backgroundFile(newValue) {
      if (newValue != null) {
        this.uploadBackgroundImage()
      } else {
        this.form.background_nj_id = null
      }
    },
  },

  methods: {
    remove() {
      if (!this.$hasPermission('naja_app_header_footer_templates_destroy')) {
        alert('Acesso negado')
        return
      }

      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.roles
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'TaskSchedulers' })
            })
            .catch(error => {
              if (error.response) {
                this.$toast.show(
                  'Erro ao remover Agendamento de Tarefas',
                  'error'
                )
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    _parseForm(data) {
      const formPayload = {
        id: data.id,
        organization_branch_id: data.organization_branch_id,
        subgroup: data.subgroup,
        margin_top: data.margin_top,
        margin_bottom: data.margin_bottom,
        header_height: data.header_height,
        footer_height: data.footer_height,
        background_nj_id: data.background_nj_id,
        header: data.header,
        footer: data.footer,
      }

      this.form = { ...this.form, ...formPayload }
    },

    uploadBackgroundImage() {
      const formData = new FormData()
      formData.append('fato_gerador', 'header_footer_background')
      formData.append('fato_gerador_id', this.form.id)
      formData.append('tipo_anexo', this.backgroundFile.type)
      formData.append('observacao', '')
      formData.append('data', '')
      formData.append('anexo', this.backgroundFile)

      const url = `${Api.baseURL}/njfiles`
      const request = axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Naja-Api-Key': Api.access_key,
          Authorization: this.$store.getters.token,
        },
      })

      request
        .then(response => {
          this.form.background_nj_id = response.data.arquivo.id
          this.fetchBackground(response.data.arquivo.id)
        })
        .catch(err => {
          this.$root.$children[0].toast('Erro ao enviar arquivo')
          throw err
        })
    },

    async fetchBackground(id) {
      try {
        const njFilesResult = await Api.njfiles.fetch(id)
        this.backgroundURL = njFilesResult.data.arquivo.url
      } catch (e) {
        this.$root.$children[0].toast('Erro ao buscar arquivo de background')
        throw e
      }
    },

    async removeBackground() {
      try {
        this.removingBackground = true
        await Api.njfiles.destroy(this.form.background_nj_id)
        this.backgroundURL = null
        this.form.background_nj_id = null
      } catch (e) {
        this.$root.$children[0].toast('Erro ao remover arquivo de background')
        throw e
      } finally {
        this.removingBackground = false
      }
    },

    async fetch(id = null) {
      try {
        this.loading = true

        const branchesResult = await Api.branches()
        this.branches = branchesResult.data.branches

        const subgroupsResult = await Api.subgroups()
        this.subgroups = subgroupsResult.data.subgroups

        if (id === null) {
          return
        }

        const templateResult = await Api.headerFooterTemplates.fetch(id)
        this._parseForm(templateResult.data.header_footer_template)

        if (templateResult.data.header_footer_template.background_nj_id) {
          await this.fetchBackground(
            templateResult.data.header_footer_template.background_nj_id
          )
        }
      } catch (e) {
        this.$root.$children[0].toast(
          'Erro ao buscar Template Cabeçalho e Rodapé'
        )
        throw e
      } finally {
        this.$scrollTop()
        this.loading = false
      }
    },

    save() {
      this.saveLoading = true

      Api.headerFooterTemplates
        .save(this.$route.params.id, {
          medical_report_template_header_footer: this.form,
        })
        .then(response => {
          this._parseForm(response.data.header_footer_template)
          this.$root.$children[0].toast(
            'Template Cabeçalho e Rodapé salvo com sucesso'
          )

          this.$router.push({
            name: 'HeaderFooterTemplateShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast(
              'Erro ao salvar template de cabeçalho e rodapé.'
            )
            if (error.response.status != 500) {
              this.errors = error.response.data.errors
              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
